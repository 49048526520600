<template>
    <custom-table
        :title="$t('pages.ecommerce.invoiceManagement.invoice.title')"
        :subTitle="$t('pages.ecommerce.invoiceManagement.invoice.subTitle')"
        :items="table.data"
        :loading="table.loading"
        :columns="fields"
        :actions="actions"
        :pagination="table.pagination"
        changeUrlPage
        filterComponentName="EcommerceInvoiceFilter"
        @action="handleClickAction"
        @changeTable="handleTableChange">
        <template v-slot:order_id="{ row: record }">
            <router-link :to="'/ecommerce/order-management/order/detail/' + record.order.id" target="_blank" class="fw-bold text-gray-600 text-hover-primary">{{ record.order.id }}</router-link>
        </template>
        <template v-slot:user="{ row: record }">
            <template v-if="record.order && record.order.address">
                <span class="fw-bolder">{{ record.order.address.full_name }}</span><br>
                <span class="fw-bolder fs-7">{{ $t('pages.ecommerce.invoiceManagement.invoice.cols.identificationNumber') }}: </span>
                <span class="fs-7">{{((record.order.address && record.order.address.identification_number) ? record.order.address.identification_number : "-") }}</span>
            </template>
            <span v-else>-</span>
         </template>
        <template v-slot:total="{ row: record }">
            {{ record.order.payment_detail ? record.order.payment_detail.payment_total + ' ' + record.order.currency.suffix : "-" }} /
            {{ record.order.payment_detail ? record.order.payment_detail.paid_total + ' ' + record.order.currency.suffix : "-" }}
        </template>
        <template v-slot:provider_invoice="{ row: record }">
            <a v-if="record.provider_runtime_errors.length" class="cursor-pointer fw-bold text-danger" v-on:click="showProviderError(record)">
                <span  class="badge badge-light-danger text-hover-primary">{{ $t('messages.error') }}!</span>
            </a>
            <span v-else :class="record.provider_invoice ? 'badge-light-success': 'badge-light-danger'" class="badge">{{ record.provider_invoice ? $t('pages.ecommerce.invoiceManagement.invoice.providerInvoice.processed') : $t('pages.ecommerce.invoiceManagement.invoice.providerInvoice.waiting') }}</span>
        </template>
        <template v-slot:provider_invoice_id="{ row: record }">
            {{ record.provider_invoice ? record.provider_invoice.provider_invoice_id : "-" }}
        </template>
        <template v-slot:invoice_status="{ row: record }">
            <span :class="status[record.status_id].badge" class="badge">{{ $t('pages.ecommerce.invoiceManagement.invoice.status.' + status[record.status_id].type) }}</span>
        </template>
        <template v-slot:provider_legalize_status="{ row: record }">
            <span v-if="record.provider_invoice" :class="provider.legalizeStatus[record.provider_invoice.provider_legalize_status_id].badge" class="badge">{{ $t('pages.ecommerce.invoiceManagement.invoice.providerLegalizeStatus.' + provider.legalizeStatus[record.provider_invoice.provider_legalize_status_id].type) }}</span>
            <span v-else>-</span>
        </template>
        <template v-slot:created_at="{ row: record }">
            {{ $moment(record.created_at).format("DD MMMM YYYY") }}<br>{{ $moment(record.created_at).format("HH:mm:ss") }}
        </template>
        <template v-slot:actions="{ row: record }">
            <div class="d-flex justify-content-end">
                <router-link :to="'/ecommerce/invoice-management/invoice/preview/' + record.id" target="_blank" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1">
                    <span class="svg-icon svg-icon-3">
                        <inline-svg src="/media/icons/duotune/general/gen004.svg" />
                    </span>
                </router-link>
                <a v-if="record.provider_invoice && record.provider_invoice.provider_legalize_status_id == 2" class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1" :class="provider.legalizePdfLoading && 'pe-none'" v-on:click="showLegalizedPdf(record)" :data-kt-indicator="provider.legalizePdfLoading ? 'on' : null">
                    <span class="svg-icon svg-icon-3" v-if="!provider.legalizePdfLoading">
                        <inline-svg src="/media/icons/duotune/general/gen005.svg" />
                    </span>
                    <span v-if="provider.legalizePdfLoading" class="indicator-progress">
                        <span class="spinner-border spinner-border-sm align-middle"></span>
                    </span>
                </a>
            </div>
        </template>
    </custom-table>

    <div class="modal fade" id="kt_modal_provider_error" ref="providerErrorModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered mw-650px">
            <div class="modal-content">
                <div class="modal-header" id="kt_modal_reservation_detail_header">
                    <h2 class="fw-bolder">{{ $t('pages.ecommerce.invoiceManagement.invoice.providerErrors.title') }}</h2>
                    <div id="kt_modal_reservation_detail_close" data-bs-dismiss="modal" class="btn btn-icon btn-sm btn-active-icon-primary">
                        <span class="svg-icon svg-icon-1">
                            <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                        </span>
                    </div>
                </div>
                <div class="modal-body py-10 px-lg-17">
                    <div class="scroll-y me-n7 pe-7" id="kt_modal_reservation_detail_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_add_customer_header" data-kt-scroll-wrappers="#kt_modal_add_customer_scroll" data-kt-scroll-offset="300px">
                        <div v-for="(error, errorIndex) in provider.errors" :key="errorIndex" :class="errorIndex != 0 && 'mt-7'">
                            <h3 class="mb-5">{{ sprintf($t('pages.ecommerce.invoiceManagement.invoice.providerErrors.errorTitlePattern'),[(errorIndex + 1)]) }}</h3>
                            <div class="fv-row mb-3">
                                <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.invoiceManagement.invoice.providerErrors.cols.provider') }}:</label>
                                <span class="text-gray-500 fw-bold fs-6">{{ error.provider.name }}</span>
                            </div>
                            <div class="fv-row mb-3">
                                <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.invoiceManagement.invoice.providerErrors.cols.createdAt') }}:</label>
                                <span class="text-gray-500 fw-bold fs-6">{{ $moment(error.created_at).format("DD.MM.YYYY - HH:mm:ss") }}</span>
                            </div>
                            <div class="fv-row mb-3">
                                <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.invoiceManagement.invoice.providerErrors.cols.errorMessage') }}:</label>
                                <span class="text-gray-500 fw-bold fs-6">{{ error.error_message }}</span>
                            </div>
                            <div class="fv-row mb-3">
                                <label class="fs-5 fw-bold mb-2 me-2">{{ $t('pages.ecommerce.invoiceManagement.invoice.providerErrors.cols.errorMessageHuman') }}:</label>
                                <span class="text-gray-500 fw-bold fs-6">{{ error.error_message_human }}</span>
                            </div>
                            <div class="fv-row mb-3">
                                <label class="fs-5 fw-bold mb-2 me-2 text-decoration-underline">{{ $t('pages.ecommerce.invoiceManagement.invoice.providerErrors.cols.errorMessageTricks') }}:</label>
                                <div class="mt-1">
                                    <div class="d-flex align-items-center"  v-for="(trick, trickIndex) in error.error_message_tricks" :key="trickIndex" :class="[trickIndex !== 0 && 'mt-2']">
                                        <span class="svg-icon svg-icon-1 svg-icon-success">
                                            <inline-svg src="/media/icons/duotune/general/gen043.svg"/>
                                        </span>
                                        <span class="ms-3 fw-bold fs-5 text-gray-500 text-start">
                                            {{ trick }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer flex-center">
                    <button class="btn btn-lg btn-primary" type="button" v-on:click="hideModal($refs.providerErrorModal)">{{ $t("btn.close") }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CustomTable from "@/components/custom-table";

export default {
    name: "index",
    components: {
        CustomTable
    },
    data() {
        return {
            fields: [
                {
                    name: this.$t("pages.ecommerce.invoiceManagement.invoice.cols.id"),
                    key: "id",
                    class: ""
                },
                {
                    name: this.$t("pages.ecommerce.invoiceManagement.invoice.cols.orderNumber"),
                    scopedSlots: {customRender: "order_id"},
                    class: ""
                },
                {
                    name: this.$t("pages.ecommerce.invoiceManagement.invoice.cols.user"),
                    scopedSlots: {customRender: "user"},
                    rowClass:"pt-0 pb-0"
                },
                {
                    name: this.$t("pages.ecommerce.invoiceManagement.invoice.cols.total"),
                    scopedSlots: {customRender: "total"}
                },
                {
                    name: this.$t('pages.ecommerce.invoiceManagement.invoice.cols.status'),
                    scopedSlots: {customRender: "invoice_status"}
                },
                {
                    name: this.$t("pages.ecommerce.invoiceManagement.invoice.cols.providerInvoice"),
                    scopedSlots: {customRender: "provider_invoice"}
                },
                {
                    name: this.$t("pages.ecommerce.invoiceManagement.invoice.cols.providerInvoiceID"),
                    scopedSlots: {customRender: "provider_invoice_id"}
                },
                {
                    name: this.$t("pages.ecommerce.invoiceManagement.invoice.cols.providerLegalizeStatus"),
                    scopedSlots: {customRender: "provider_legalize_status"}
                },
                {
                    name: this.$t('pages.ecommerce.invoiceManagement.invoice.cols.createdAt'),
                    scopedSlots: {customRender: "created_at"},
                    rowClass:"pt-0 pb-0"
                },
                {
                    name: this.$t("common.action"),
                    key: "action",
                    scopedSlots: {customRender: "actions"}
                }
            ],
            actions: [
                {
                    name: "refresh",
                    icon: "bi-arrow-repeat",
                    label: "btn.refresh"
                }
            ],
            status: [
                {
                    type: 'notPaid',
                    badge: 'badge-light-danger'
                },
                {
                    type: 'paid',
                    badge: 'badge-light-success'
                },
                {
                    type: 'cancelled',
                    badge: 'badge-light-dark'
                }
            ],
            provider:{
                errors: [],
                legalizeStatus: [
                    {
                        type: 'notStart',
                        badge: 'badge-light-dark'
                    },
                    {
                        type: 'pending',
                        badge: 'badge-light-warning'
                    },
                    {
                        type: 'success',
                        badge: 'badge-light-success'
                    },
                    {
                        type: 'error',
                        badge: 'badge-light-danger'
                    }
                ],
                legalizePdfLoading: false
            }
        }
    },
    computed: {
        table() {
            return this.$store.state.ecommerce.invoiceManagement.invoice.table;
        }
    },
    mounted() {
        this.setCurrentPageBreadcrumbs(this.$t("menu.invoices"), [this.$t("menu.invoiceManagement")]);

        this.$store.dispatch('ecommerce/invoiceManagement/invoice/get', {
            page: {
                current: this.$route.query.page
            },
            filterData: Object.assign( {
                payment_method_approved: true,
                sort: 'created_at:desc',
            }, this.$root.getFilterWithUrl({}))
        });
    },
    methods: {
        showProviderError(record){
            this.provider.errors = record.provider_runtime_errors;
            this.showModal(this.$refs.providerErrorModal);
        },
        showLegalizedPdf(record) {
            this.provider.legalizePdfLoading = true;

            this.axios.get(this.endpoints['ecommerce_invoice_show_legalized_pdf'] + '/' + record.id).then((response) => {
                let data = response.data.data;
                window.open(data.url, '_blank');
            }).finally(() =>{
                this.provider.legalizePdfLoading = false;
            }).catch((error) =>{
                if (error.response.data.message == 'not_created') {
                    error.response.data.message = this.$t('pages.ecommerce.invoiceManagement.invoice.showLegalizedPdf.responseMessages.notCreated');
                }

                this.onResponseFailure(error.response.data);
            })
        },
        handleClickAction(name) {
            switch (name) {
                case "refresh":
                    this.refreshTable();
                    break;

                default:
                    break;
            }
        },
        refreshTable() {
            this.$store.dispatch("ecommerce/invoiceManagement/invoice/refresh");
        },
        handleTableChange(pagination, filterData) {
            this.$store.dispatch("ecommerce/invoiceManagement/invoice/get", {
                page: pagination,
                filterData: Object.assign({
                    payment_method_approved: true,
                    sort: 'created_at:desc',
                }, filterData)
            });
        },
    }
}
</script>

<style>
</style>